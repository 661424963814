import React from 'react';
import { Container , Row, Col } from 'react-bootstrap';
import PageHero from '../Components/PageHero';

function Zaden() {
    document.title = "Zaden, bollen e.d. - Tuincentrum Boers B.V";

    return (
        <>
        <PageHero image={"./tuincentrum-voorkant.jpg"} imageAlt="Zaden, bollen e.d." />
        <Container>
        <div className="page-content">
                <Row>
                    <Col xs={12} md={6}>
                        <img src="./img/assortiment/bol.jpg" />
                    </Col>
                    <Col xs={12} md={6}>
                        <p>
                            Het is altijd weer leuk om zelf bloemen te zaaien of bollen/knollen te poten en in het voorjaar of in de zomer de groene puntjes boven de grond te zien verschijnen. Op de verpakking kunt u lezen wanneer u kunt zaaien of poten en vindt u aanwijzingen betreffende standplaats, pootdiepte enz.
                        </p>
                    </Col>
                </Row>
            </div>
        </Container>
        </>
    )
}

export default Zaden;