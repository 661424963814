import React from 'react';
import { Container , Row, Col } from 'react-bootstrap';
import PageHero from '../Components/PageHero';

function Buitenpotterie() {
    document.title = "Buitenpotterie - Tuincentrum Boers B.V";

    return (
        <>
        <PageHero image={"./tuincentrum-voorkant.jpg"} imageAlt="Buitenpotterie" />
        <Container>
            <div className="page-content">
                <Row>
                    <Col xs={12} md={6}>
                        <img src="./img/assortiment/buitenpotten.jpg" />
                    </Col>
                    <Col xs={12} md={6}>
                        <p>
                        Maak uw tuin, terras of balkon extra sfeervol met onze buitenpotterie. Verkrijgbaar in heel veel soorten. Of u nu kiest voor kunststof, aardewerk of mandwerk, wij hebben een uitgebreid assortiment. Door de ruime keuze, vindt u altijd een stijl die bij u en uw tuin past.
                        </p>
                    </Col>
                </Row>
            </div>
        </Container>
        </>
    )
}

export default Buitenpotterie;
