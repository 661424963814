import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Adresgegevens, Openingstijden } from '../Components/index';
import PageHero from '../Components/PageHero';

function Contact() {
  document.title = "Contact - Tuincentrum Boers B.V";

    return (
      <>
      <PageHero image={"./tuincentrum-voorkant.jpg"} imageAlt="Contact" />
      <Container>
        <Row>
          <Col xs={12} md={8}>
            <div className="page-content">
              <h2>Routebeschrijving - Let op! Gewijzigde verkeerssituatie</h2>
              <h3>Wanneer u vanuit Maassluis via de Westlandseweg komt:</h3>
              <p>
                Rij de Westlandseweg helemaal af en ga alsmaar rechtdoor tot de stoplichten voorbij het Shell station (naast eetcafé Westgaag).<br/>
                Ga bij de stoplichten linksaf en sorteer daarna links voor om via de nieuwe afrol ons parkeerterrein op te rijden. <br/>
                Terug kunt u dezelfde weg omhoog de dijk op, rechtsaf richting het Shell station en bij de stoplichten rechtsaf richting Maassluis.
              </p>
              <h3>Wanneer u vanuit de richting van Rotterdam komt:</h3>
              <p>
                Op de A20 neemt u de afslag Maassluis West.<br />
                Aan het einde van de afrit rijdt u de buitenste ring van de rotonde op.<br />
                U gaat richting Hoek van Holland / Maasdijk.<br />
                Bij de stoplichten gaat u rechtdoor en sorteert u daarna links voor om via de nieuwe afrol ons parkeerterrein op te rijden. <br/>
              </p>
              <h3>Wanneer u vanuit de richting van Hoek van Holland komt:</h3>
              <p>
                U neemt de A20 en volgt de borden richting Maasdijk Oost.<br />
                Vervolgens neemt u afrit 6 Maasdijk/Maassluis West en gaat onderaan de afrit rechtsaf. <br />
                Bij de stoplichten gaat u rechtdoor en sorteert u daarna links voor om via de nieuwe afrol ons parkeerterrein op te rijden.
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <Openingstijden />
            <Adresgegevens />
          </Col>
        </Row>
      </Container>
      </>
    )
  }

  export default Contact;