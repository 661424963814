import React from 'react';
import { Container} from 'react-bootstrap';
import PageHero from '../Components/PageHero';
import { Link } from "react-router-dom";

function NoMatch() {
    document.title = "Pagina niet gevonden - Tuincentrum Boers B.V";

    return (
        <>
        <PageHero image={"./tuincentrum-voorkant.jpg"} imageAlt="" />
        <Container>
            <div className="page-content">
                <h1>Helaas, deze pagina is niet gevonden.</h1>
                <p>Mogelijk is deze pagina verplaatst of bestaat deze niet meer.</p>
                <Link className="button nav-link" to="/">Terug naar de voorpagina</Link>
            </div>
        </Container>
        </>
    )
}

export default NoMatch;