import React from 'react';
import { Container , Row, Col } from 'react-bootstrap';
import PageHero from '../Components/PageHero';

function Gereedschap() {
    document.title = "Gereedschap - Tuincentrum Boers B.V";

    return (
        <>
        <PageHero image={"./tuincentrum-voorkant.jpg"} imageAlt="Gereedschap" />
        <Container>
        <div className="page-content">
                <Row>
                    <Col xs={12} md={6}>
                        <img src="./img/assortiment/gereedschap.jpg" />
                    </Col>
                    <Col xs={12} md={6}>
                        <h2>Talen Tools</h2>
                        <p>
                            Talen Tools tuingereedschap is geschikt voor hoveniers en tuinliefhebbers. Goed tuingereedschap is absoluut noodzakelijk voor aanleg en onderhoud van uw tuin. U kunt met dit gereedschap professioneel aan de slag. Wij hebben een flink gedeelte van het Talen Tools assortiment in de schappen. En…een goed stuk gereedschap…is het halve werk!
                        </p>
                        <h2>Kixx</h2>
                        <p>
                            Ook voor Kixx tuinhandschoenen in alle soorten en maten, kunt u bij ons terecht. Voor elke klus hebben wij de juiste Kixx handschoenen.
                        </p>
                    </Col>
                </Row>
            </div>
        </Container>
        </>
    )
}

export default Gereedschap;