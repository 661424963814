import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import {
    Home, NoMatch, Welkom, Assortiment, Cadeaukaart,
    Contact, Nieuw, Binnenpotterie, Buitenpotterie, Gereedschap, Gewasbescherming,
    Kamerplanten, Meststoffen, Moestuin, Overige, Sfeer, Tuinplanten, Zaden, Zijde
} from "./Pages/index";
import { Footer, Menu } from './Components';
import Graszoden from './Pages/Graszoden';

export const ApplicationRouter = () => {
    return (
        <Router>
            <Switch>
                <Route>
                    <Menu />
                    <main>
                        <Switch>
                            <Route exact path="/">
                                <Home />
                            </Route>

                            <Route path="/contact" component={Contact} />
                            <Route path="/contact.html">
                                <Redirect to="/contact" />
                            </Route>

                            <Route path="/graszoden" component={Graszoden} />

                            <Route path="/nieuw" component={Nieuw} />
                            <Route path="/nieuw.html">
                                <Redirect to="/nieuw" />
                            </Route>

                            <Route path="/assortiment" component={Assortiment} />
                            <Route path="/assortiment.html">
                                <Redirect to="/assortiment" />
                            </Route>

                            <Route path="/binnenpotterie" component={Binnenpotterie} />
                            <Route path="/binnenpotterie.html">
                                <Redirect to="/binnenpotterie" />
                            </Route>

                            <Route path="/buitenpotterie" component={Buitenpotterie} />
                            <Route path="/buitenpotterie.html">
                                <Redirect to="/buitenpotterie" />
                            </Route>

                            <Route path="/cadeaukaart" component={Cadeaukaart} />
                            <Route path="/cadeaukaart.html">
                                <Redirect to="/cadeaukaart" />
                            </Route>

                            <Route path="/gereedschap" component={Gereedschap} />
                            <Route path="/gereedschap.html">
                                <Redirect to="/gereedschap" />
                            </Route>

                            <Route path="/gewasbescherming" component={Gewasbescherming} />
                            <Route path="/gewasbescherming.html">
                                <Redirect to="/gewasbescherming" />
                            </Route>

                            <Route path="/kamerplanten" component={Kamerplanten} />
                            <Route path="/kamerplanten.html">
                                <Redirect to="/kamerplanten" />
                            </Route>

                            <Route path="/meststoffen" component={Meststoffen} />
                            <Route path="/meststoffen.html">
                                <Redirect to="/meststoffen" />
                            </Route>

                            <Route path="/moestuin" component={Moestuin} />
                            <Route path="/moestuin.html">
                                <Redirect to="/moestuin" />
                            </Route>

                            <Route path="/overige" component={Overige} />
                            <Route path="/overige.html">
                                <Redirect to="/overige" />
                            </Route>

                            <Route path="/sfeer" component={Sfeer} />
                            <Route path="/sfeer.html">
                                <Redirect to="/sfeer" />
                            </Route>

                            <Route path="/tuinplanten" component={Tuinplanten} />
                            <Route path="/tuinplanten.html">
                                <Redirect to="/tuinplanten" />
                            </Route>

                            <Route path="/zaden" component={Zaden} />
                            <Route path="/zaden.html">
                                <Redirect to="/zaden" />
                            </Route>

                            <Route path="/zijde" component={Zijde} />
                            <Route path="/zijde.html">
                                <Redirect to="/zijde" />
                            </Route>

                            <Route path="/welkom" component={Welkom} />
                            <Route path="/welkom.html">
                                <Redirect to="/welkom" />
                            </Route>

                            <Route path='*' component={NoMatch} />
                        </Switch>
                    </main>
                    <Footer />
                </Route>
            </Switch>
        </Router>
    )
}