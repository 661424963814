import React from 'react';
import { Container , Row, Col } from 'react-bootstrap';
import PageHero from '../Components/PageHero';

function Graszoden() {
    document.title = "Graszoden - Tuincentrum Boers B.V";

    return (
        <>
        <PageHero image={"./tuincentrum-voorkant.jpg"} imageAlt="Graszoden" />
        <Container>
        <div className="page-content">
                <Row>
                    <Col xs={12} md={6}>
                        <p>
                            Graszoden zijn alleen op bestelling leverbaar. <br /> Bij mooi weer en voldoende bestellingen, zijn er weer regelmatig graszoden
                        </p>

                        <p>
                            Bestel uw graszoden uiterlijk voor woensdag 12:00 uur. De graszoden kunnen op vrijdag worden afgehaald nadat u door ons bent gebeld. <br /> Afhaalprijs graszoden € 4,50 per m2
                        </p>
                    </Col>
                    <Col xs={12} md={6}>
                        <img src="./img/home/graszoden.jpg" />
                    </Col>
                </Row>
            </div>
        </Container>
        </>
    )
}

export default Graszoden;