import React from 'react';
import { Container } from 'react-bootstrap';
import '../styles/ComponentStyles/Hero.scss';

export const PageHero = (props: any) => {
    return (
        <>
        <div className="hero-cnt">
            <img className="hero" src={`${process.env.PUBLIC_URL}/img/pagehero/${props.image}`} alt={props.imageAlt} />
        </div>
        <Container>
            <h1>{props.imageAlt}</h1>
        </Container>
        </>
    )
}

export default PageHero;