import React from 'react';
import { Container , Row, Col } from 'react-bootstrap';
import PageHero from '../Components/PageHero';

function Cadeaukaart() {
    document.title = "Cadeaukaart - Tuincentrum Boers B.V";

    return (
        <>
        <PageHero image={"./tuincentrum-voorkant.jpg"} imageAlt="Tuincentrum Boers Cadeaukaart" />
        <Container>
            <div className="page-content">
                <Row>
                    <Col xs={12} md={6}>
                        <img src="./img/home/cadeaukaart.jpg" />
                    </Col>
                    <Col xs={12} md={6}>
                        <p>
                            U kunt bij ons terecht voor een Tuincentrum Boers Cadeaukaart. Onze cadeaukaart is onbeperkt geldig en verkrijgbaar in elk gewenst bedrag.
                        </p>
                        <h2>Met de Tuincentrum Boers Cadeaukaart heeft u altijd een geslaagd cadeau!</h2>
                    </Col>
                </Row>
            </div>
        </Container>
        </>
    )
}

export default Cadeaukaart;
