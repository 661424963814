import React from 'react';
import { Container , Row, Col } from 'react-bootstrap';
import PageHero from '../Components/PageHero';

function Binnenpotterie() {
    document.title = "Binnenpotterie - Tuincentrum Boers B.V";

    return (
        <>
        <PageHero image={"./binnenpotterie.jpg"} imageAlt="Binnenpotterie" />
        <Container>
            <div className="page-content m-b">
                <Row>
                    <Col xs={12} md={4}>
                        <img src="./img/assortiment/binnenpotten.jpg" />
                    </Col>
                    <Col xs={12} md={6}>
                        <p>
                        Bij ons bent u op het juiste adres voor uw kunststof of aardewerk binnenpotterie in alle soorten en maten. Er is voor elk wat wils. Indien u uw keuze heeft gemaakt, kunt u uw pot eventueel vakkundig laten opvullen door één van onze medewerk(st)ers.* Onze potterie wordt wekelijks aangevuld en vernieuwd. Omdat er met grote regelmaat nieuwe pottenseries zijn, verwijzen we u naar Instagram waar een overzicht is te vinden van de nieuwste potterie. Kom langs en maak uw keuze uit ons ruime assortiment.
                        <br/>
                        <br/>
                        *tijdens koopavonden is het voor ons helaas niet mogelijk om uw bloempotten of plantenbakken te vullen. Het is wel mogelijk om e.e.a. uit te zoeken en op een later tijdstip bij ons op te halen.
                        </p>
                    </Col>
                </Row>
            </div>
            <Row>
                {/* <Col xs={12} sm={6} md={4}>
                    <img className="img-fluid m-b" src="./img/assortiment/binnenpotterie/binnenpotterie2.jpg" />
                </Col> */}
            </Row>
        </Container>
        </>
    )
}

export default Binnenpotterie;