import React from 'react';
import { Container , Row, Col } from 'react-bootstrap';
import PageHero from '../Components/PageHero';

function Gewasbescherming() {
    document.title = "Gewasbescherming - Tuincentrum Boers B.V";

    return (
        <>
        <PageHero image={"./tuincentrum-voorkant.jpg"} imageAlt="Gewasbescherming/ bestrijdingsmiddelen" />
        <Container>
        <div className="page-content">
                <Row>
                    <Col xs={12} md={6}>
                        <img src="./img/assortiment/gewas.jpg" />
                    </Col>
                    <Col xs={12} md={6}>
                        <p>
                            Bij ons kunt u terecht voor bestrijdingsmiddelen van Bayer en Ecostyle. Voordat u tot bestrijding over gaat, adviseren wij u zich goed te verdiepen in hetgeen u wilt bestrijden en altijd goed de gebruiksaanwijzing te lezen. Bij ons in het tuincentrum treft u een computerscherm aan waarop u advies kunt vinden welk middel het beste past bij uw tuinprobleem.
                        </p>
                    </Col>
                </Row>
            </div>
        </Container>
        </>
    )
}

export default Gewasbescherming;