import React from 'react';
import { Container , Row, Col } from 'react-bootstrap';
import PageHero from '../Components/PageHero';

function Overige() {
    document.title = "Overige - Tuincentrum Boers B.V";

    return (
        <>
        <PageHero image={"./tuincentrum-voorkant.jpg"} imageAlt="Overige" />
        <Container>
        <div className="page-content">
                <Row>
                    <Col xs={12} md={6}>
                        <img src="./img/assortiment/vogelhuis.jpg" />
                    </Col>
                    <Col xs={12} md={6}>
                        <ul>
                            <li>(Geur)kaarsen o.a. Bolsius</li>
                            <li>Bloemen- en groentezaden</li>
                            <li>Bloembollen</li>
                            <li>Stenen voor decoratie van uw tuin (o.a. Limburgs grind in diverse maten)</li>
                            <li>Decoratiehout voor bloemen- en plantenarrangementen of andere creatieve doeleinden</li>
                            <li>Hobbymaterialen (o.a. steekschuim, krammen, steentjes enz. enz.)</li>
                            <li>Glaswerk (vazen en schalen)</li>
                            <li>Mandwerk o.a. kranzen</li>
                            <li>Kunststof o.a.(tuin)gieters</li>
                            <li>Vogelhuisjes en insectenhotels</li>
                        </ul>
                    </Col>
                </Row>
            </div>
        </Container>
        </>
    )
}

export default Overige;