import React from 'react';
import { Container , Row, Col } from 'react-bootstrap';
import PageHero from '../Components/PageHero';

function Sfeer() {
    document.title = "Cadeau en sfeerartikelen - Tuincentrum Boers B.V";

    return (
        <>
        <PageHero image={"./tuincentrum-voorkant.jpg"} imageAlt="Cadeau- &amp; Sfeerartikelen" />
        <Container>
            <div className="page-content m-b">
                <Row>
                    <Col xs={12} md={4}>
                        <img src="./img/assortiment/sfeer/sfeer.jpg" />
                    </Col>
                    <Col xs={12} md={6}>
                        <p>
                            Accessoires zijn echte sfeerbepalers in uw woon- of werkruimte en zijn in ruime mate te vinden op onze cadeau- en sfeerafdeling. Het assortiment wisselt met grote regelmaat en onze sfeerafdeling ondervindt derhalve regelmatig een metamorfose. Als u op de hoogte wilt blijven van de nieuwste cadeau- en sfeerartikelen, volg ons dan op Instagram. 
                            <br/><br/>
                            Kom sfeer proeven en doe inspiratie op in ons tuincentrum.
                        </p>
                    </Col>
                </Row>
            </div>
            <Row>
                {/* <Col xs={12} sm={6} md={4}>
                    <img className="img-fluid m-b" src="./img/assortiment/sfeer/sfeer2.jpg" />
                </Col> */}
            </Row>
        </Container>
        </>
    )
}

export default Sfeer;