import React from 'react';
import { Container , Row, Col } from 'react-bootstrap';
import { AssortiBlock } from '../Components/index';
import PageHero from '../Components/PageHero';

export const Assortiment = () => {
    document.title = "Assortiment - Tuincentrum Boers B.V";

    return (
        <>
        <PageHero image={"./tuincentrum-voorkant.jpg"} imageAlt="" />
        <Container>
        <Row>
            <Col xs={12} md={4}><AssortiBlock image="img/assortiment/sfeer.jpg" link="/sfeer" title="Cadeau-  &amp; sfeerartikelen" /></Col>
            <Col xs={12} md={4}><AssortiBlock image="img/assortiment/zijde.jpg" link="/zijde" title="Zijdebloemen en -planten" /></Col>
            <Col xs={12} md={4}><AssortiBlock image="img/assortiment/kamerplant.jpg" link="/kamerplanten" title="Kamerplanten" /></Col>
            <Col xs={12} md={4}><AssortiBlock image="img/assortiment//binnenpotten.jpg" link="/binnenpotterie" title="Binnenpotterie" /></Col>
            <Col xs={12} md={4}><AssortiBlock image="img/assortiment/buitenpotten.jpg" link="/buitenpotterie" title="Buitenpotterie" /></Col>
            <Col xs={12} md={4}><AssortiBlock image="img/assortiment/tuinplanten.jpg" link="/tuinplanten" title="Tuinplanten" /></Col>
            <Col xs={12} md={4}><AssortiBlock image="img/assortiment/moestuin.jpg" link="/moestuin" title="Moestuin" /></Col>
            <Col xs={12} md={4}><AssortiBlock image="img/assortiment/grond.jpg" link="/meststoffen" title="Grond- en meststoffen" /></Col>
            <Col xs={12} md={4}><AssortiBlock image="img/assortiment/gewas.jpg" link="/gewasbescherming" title="Gewasbescherming" /></Col>
            <Col xs={12} md={4}><AssortiBlock image="img/assortiment/bol.jpg" link="/zaden" title="Zaden, bollen, e.d." /></Col>
            <Col xs={12} md={4}><AssortiBlock image="img/assortiment/gereedschap.jpg" link="/gereedschap" title="Gereedschap" /></Col>
            <Col xs={12} md={4}><AssortiBlock image="img/assortiment/vogelhuis.jpg" link="/overige" title="Overige" /></Col>
          </Row>
        </Container>
        </>
    )
}
