import React from 'react';
import '../styles/ComponentStyles/Homeblock.scss';

export const Openingstijden = (props: any) => {
    return (
        <div className="block-info">
            <h2>Openingstijden</h2>
            <table>
                <tbody>
                    <tr>
                        <td>Maandag</td>
                        <td>8:30 tot 18:00 uur</td>
                    </tr>
                    <tr>
                        <td>Dinsdag</td>
                        <td>8:30 tot 18:00 uur</td>
                    </tr>
                    <tr>
                        <td>Woensdag</td>
                        <td>8:30 tot 18:00 uur</td>
                    </tr>
                    <tr>
                        <td>Donderdag</td>
                        <td>8:30 tot 18:00 uur</td>
                    </tr>
                    <tr>
                        <td>Vrijdag</td>
                        <td>8:30 tot 18:00 uur <br /> (geen koopavond)</td>
                    </tr>
                    <tr>
                        <td>Zaterdag</td>
                        <td>8:30 tot 17:00 uur</td>
                    </tr>
                    <tr>
                        <td>Zondag</td>
                        <td>Gesloten</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
