import React, { useState } from 'react';
import { Container , Navbar, Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";
import '../styles/App.scss';
import '../styles/ComponentStyles/Navbar.scss';

export const Menu = () => {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <>
    <Container className="d-none d-lg-block">
      <div className="logo-cnt">
        <a className="logo" href="/">
          <img src={`${process.env.PUBLIC_URL}/logo-tuincentrumboersbv.png`} alt="Logo Tuincentrum Boers B.V." />
        </a>
      </div>
    </Container>
    <Navbar id="navbar" variant="dark" expand="lg" expanded={isExpanded}>
      <Container>
          <Navbar.Toggle aria-controls="navbar-nav" onClick={() => setExpanded(isExpanded ? false : true)} />
          <Navbar.Collapse id="navbar-nav" className="menu-cnt">
              <Nav className="mr-auto">
              </Nav>
              <Nav activeKey={'/'}>
                <Nav.Link onClick={() => setExpanded(false)} as={Link} to="/">Home</Nav.Link>
                {/* <Nav.Link onClick={() => setExpanded(false)} as={Link} to="/bestel">Bestellen</Nav.Link> */}
                <Nav.Link onClick={() => setExpanded(false)} as={Link} to="/contact">Contact</Nav.Link>
                {/* <Nav.Link onClick={() => setExpanded(false)} as={Link} to="/aanbiedingen">Aanbiedingen</Nav.Link> */}
                <Nav.Link onClick={() => setExpanded(false)} as={Link} to="/assortiment">Assortiment</Nav.Link>
              </Nav>
          </Navbar.Collapse>
      </Container>
      </Navbar>
      <div className="logo-mob-cnt d-block d-lg-none">
        <a className="logo" href="/">
          <img src={`${process.env.PUBLIC_URL}/logo-tuincentrumboersbv.png`} alt="Logo Tuincentrum Boers B.V." />
        </a>
      </div>
    </>
  )
}
