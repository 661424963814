import React from 'react';
import { Container , Row, Col } from 'react-bootstrap';
import PageHero from '../Components/PageHero';

function Zijde() {
    document.title = "Zijdebloemen en -planten - Tuincentrum Boers B.V";

    return (
        <>
        <PageHero image={"./tuincentrum-voorkant.jpg"} imageAlt="Zijdebloemen en -planten" />
        <Container>
        <div className="page-content m-b">
                <Row>
                    <Col xs={12} md={4}>
                        <img src="./img/assortiment/zijde/zijde2.jpg" />
                    </Col>
                    <Col xs={12} md={6}>
                        <p>
                            Zijdebloemen zijn uitermate geschikt voor het maken van leuke, kleurige arrangementen. Ze zijn er in vele soorten en kleuren en zijn geliefd bij jong en oud. Een donker hoekje in huis verandert u in een handomdraai in een gezellig sfeervol plekje met een arrangement of boeket van zijdebloemen.
                            <br/><br/>
                            Heeft u liever een plant op die donkere plek, maar wil geen enkele plant daar goeien/bloeien, kies dan voor een zijdeplant. De oplossing voor het donkerste hoekje in huis of op het werk.
                        </p>
                    </Col>
                </Row>
            </div>
            <Row>
                <Col xs={12} sm={6} md={4}>
                    <img className="img-fluid m-b" src="./img/assortiment/zijde/zijde3.jpg" />
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default Zijde;