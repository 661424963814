import React from 'react';
import { Container , Row, Col } from 'react-bootstrap';
import PageHero from '../Components/PageHero';

function Meststoffen() {
    document.title = "Grond- en meststoffen - Tuincentrum Boers B.V";

    return (
        <>
        <PageHero image={"./tuincentrum-voorkant.jpg"} imageAlt="Grond- en meststoffen" />
        <Container>
        <div className="page-content">
                <Row>
                    <Col xs={12} md={4}>
                        <img src="./img/assortiment/grond.jpg" />
                    </Col>
                    <Col xs={12} md={8}>
                        <h2>JARDINO Grond</h2>
                        <p>
                        JARDINO is het potgrondmerk voor iedere tuinliefhebber. Uw planten verdienen de allerbeste grond. Grond die speciaal is samengesteld voor specifieke plantsoorten. Grond met zorg vervaardigd en van de allerhoogste kwaliteit. Daarom kiest u voor JARDINO. U ziet het resultaat in uw planten terug. Door te kiezen voor de producten van JARDINO, legt u een ultieme basis voor de groei en bloei van uw planten. Hieronder omschrijven wij voor u een aantal van de JARDINO producten die bij ons verkrijgbaar zijn:

<ul>
    <li>JARDINO Universeel: Hoogwaardige kwaliteit potgrond voorzien van voldoende voeding voor 2 maanden, verkrijgbaar in zakken van 10, 20 en 40 liter. Geschikt voor de meest voorkomende (kamer)planten.</li>
    <li>
JARDINO Tuinaarde: Geschikt voor het verbeteren van de bodemstructuur, verkrijgbaar in zakken van 40 liter. Zeer uitgebalanceerd mengsel van hoge kwaliteit grondstoffen incl. cocos, gecomposteerde schors en biologische bemesting om de bodem in balans te brengen. Bevat voldoende voeding voor 2 maanden.
    </li>
    <li>
    JARDINO Cocosgrond: Geschikt voor gebruik binnen en buiten voor de meest voorkomende planten. Verkrijgbaar in zakken van 40 liter. Zorgt voor een hoge vochtopname. Bevat voldoende voeding voor 2 maanden.

    </li>
    <li>
    JARDINO Heide/Rhodo: Geschikt voor zuurminnende gewassen als rhododendrons, coniferen, hortensia's en heideplanten. Verkrijgbaar in zakken van 40 liter. Bevat tevens natuurcompost voor een actief bodemleven.

    </li>
    <li>
     JARDINO Sierschors: Sierschors van hoogstaande kwaliteit die gebruikt kan worden als decoratielaag tussen uw planten of als afdeklaag tegen onkruid tussen uw beplanting. Verkrijgbaar in zakken van 60 liter.

    </li>
    <li>
     JARDINO Franse schors: Een hoogstaande kwaliteit Franse schors. Deze schors verteert zeer langzaam en is ideaal voor het aanleggen van looppaden.

    </li>
    <li>
    JARDINO Terrasplantengrond: Geschikt voor de meest voorkomende terrasplanten in bloembakken en hanging baskets, mediterrane planten en palmen. Verkrijgbaar in zakken van 40 liter.

    </li>
    <li>
    JARDINO Zaai- en stekgrond: Geschikt voor alle soorten zaden, stekken en sporen. Voor gebruik binnen en buiten. Extra toegevoegd zand zorgt voor een goede waterdoorlaatbaarheid. Verkrijgbaar in zakken van 20 liter.

    </li>
</ul>

                        Kom voor het complete assortiment JARDINO grond naar ons tuincentrum of kijk op www.Jardino.nl voor meer informatie.
                        </p>
                        <h2>POKON/ECOSTYLE Meststoffen</h2>
                        <p>
                            Naast goede grond hebben uw planten behoefte aan een goede voeding om gezond en sterk te blijven. Geef uw planten daarom naast water ook regelmatig een portie voeding. Het resultaat zal snel zichtbaar zijn. Groen blad wordt groener, de bloei wordt uitbundiger en plantenziekten krijgen minder kans. Wij hebben een heel groot assortiment voedingsstoffen, bodemverbeteraars enz. van de merken POKON en ECOSTYLE.
                            Maak uw tuin, terras of balkon extra sfeervol met onze buitenpotterie. Verkrijgbaar in heel veel soorten. Of u nu kiest voor kunststof, aardewerk of mandwerk, wij hebben een uitgebreid assortiment. Door de ruime keuze, vindt u altijd een stijl die bij u en uw tuin past.
                        </p>
                    </Col>
                </Row>
            </div>
        </Container>
        </>
    )
}

export default Meststoffen;