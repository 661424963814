import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Adresgegevens, Slider, HomeBlock, Openingstijden } from '../Components/index'

function Home() {
  return (
    <>
      <Slider />
      <Container id="home-cnt">
        <Row>
          <Col xs={{ span: 12, order: 1 }} sm={{ order: 1, span: 12 }} md={6} lg={4}>
            <HomeBlock button={true} blockId="welkom" buttonText="Lees meer" link="/welkom" title="Welkom bij Tuincentrum Boers B.V." />
          </Col>
          <Col xs={{ span: 12, order: 3 }} sm={{ order: 3, span: 12 }} md={6} lg={4}>
            <Openingstijden />
          </Col>
          <Col xs={{ span: 12, order: 6 }} sm={{ order: 6, span: 12 }} md={6} lg={4}>
            <HomeBlock button={true} blockId="instagram" buttonText="Volg ons" link="https://www.instagram.com/tuincentrumboersbv" title="Volg ons op Instagram" />
          </Col>
          <Col xs={{ span: 12, order: 2 }} sm={{ order: 2, span: 12 }} md={6} lg={4}>
            <HomeBlock button={false} blockId="vogels" buttonText="Lees meer" title="Help de vogels de winter door" />
          </Col>
          <Col xs={{ span: 12, order: 5 }} sm={{ order: 5, span: 12 }} md={6} lg={4}>
            <HomeBlock button={true} blockId="bon" buttonText="Lees meer" link="/cadeaukaart" title="Cadeaukaart" />
          </Col>
          {/* <Col xs={{ span: 12, order: 7 }} sm={{ order: 7, span: 12 }} md={6} lg={4}>
            <HomeBlock button={true} blockId="zijde" buttonText="Bekijk meer" link="/zijde" title="Zijdebloemen en -planten" />
          </Col> */}
          <Col xs={{ span: 12, order: 8 }} sm={{ order: 8, span: 12 }} md={6} lg={4}>
            <Adresgegevens />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Home;