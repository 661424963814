import React from 'react';
import '../styles/ComponentStyles/Assortiblock.scss';

export const AssortiBlock = (props: any) => {
  return (
      <a href={props.link}>
        <div className="assortiment-block">
          <img src={`${process.env.PUBLIC_URL}/${props.image}`} alt={props.title} />
          <div className="desc">
            <h2>{props.title}</h2>
            <button>Lees meer</button>
          </div>
        </div>
      </a>
  )
}
