import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faLeaf } from '@fortawesome/free-solid-svg-icons'
import '../styles/ComponentStyles/Footer.scss';

library.add(fab, faInstagram, faLeaf)

export const Footer = () => {
  var date = new Date();
  var year = date.getFullYear();

  return (
      <footer>
          <Container>
              <div id="copyright"><p>&copy; {year} Tuincentrum Boers B.V.</p></div>
              <div id="icons">
                <a href="https://www.instagram.com/tuincentrumboersbv">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="https://nl-nl.facebook.com/tuincentrumboersbv">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a href="http://www.tuincentrumoverzicht.nl/tuincentrum-boers-b-v-maasdijk">
                  <FontAwesomeIcon icon={faLeaf} />
                </a>
              </div>
          </Container>
      </footer>
  ) 
}
