import React from 'react';
import { ApplicationRouter } from './ApplicationRouter';
import './styles/App.scss';

function App() {
  return (
    <>
      <ApplicationRouter />
    </>
  );
}

export default App;
