import React from 'react';
import '../styles/ComponentStyles/Homeblock.scss';

export const HomeBlock = (props: any) => {
  return (
      <a href={props.link}>
        <div className="home-block" id={props.blockId}>
            <div className="text-shadow">
                <h2>{props.title}</h2>
                <h2>{props.subtitle}</h2>
            </div>
            {props.button === true ? <button>{props.buttonText}</button> : <></>}
        </div>
      </a>
  )
}
