import React from 'react';
import '../styles/ComponentStyles/Homeblock.scss';

export const Adresgegevens = () => {
  return (
    <div className="block-info">
        <h2>Adresgegevens</h2>
        <span>Maasdijk 166</span>
        <span>2676 LC Maasdijk</span>
        <span>Telefoonnummer: 0174 - 512180</span>
    </div>
  )
}
