import React from 'react';
import { Container } from 'react-bootstrap';
import PageHero from '../Components/PageHero';

function Nieuw() {
    document.title = "Nieuw - Tuincentrum Boers B.V";

    return (
        <>
        <PageHero image={"./tuincentrum-voorkant.jpg"} imageAlt="Nieuw" />
        <Container>
            <div className="page-content">
                
            </div>
        </Container>
        </>
    )
}

export default Nieuw;