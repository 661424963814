import React from 'react';
import { Container , Row, Col } from 'react-bootstrap';
import PageHero from '../Components/PageHero';

function Kamerplanten() {
    document.title = "Kamerplanten - Tuincentrum Boers B.V";

    return (
        <>
        <PageHero image={"./tuincentrum-voorkant.jpg"} imageAlt="Kamerplanten" />
        <Container>
        <div className="page-content">
            <Row>
                <Col xs={12} md={6}>
                    <img src="./img/assortiment/kamerplant.jpg" />
                </Col>
                <Col xs={12} md={6}>
                    <p>
                        Kamerplanten zijn onmisbaar in elk interieur. Zij bepalen mede de sfeer in uw woonkamer of werkruimte. Ruime sortering groene- en bloeiende kamerplanten. Of u op zoek bent naar een klein hangplantje of een grote palm, bij ons vindt u wat u zoekt.
                        <br/><br/>
                        Bij ons bent u op het juiste adres voor uw groene en bloeiende kamerplanten.
                    </p>
                </Col>
            </Row>
        </div>
        </Container>
        </>
    )
}

export default Kamerplanten;