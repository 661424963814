import React from 'react';
import { Container , Row, Col } from 'react-bootstrap';
import PageHero from '../Components/PageHero';

function Moestuin() {
    document.title = "Moestuin - Tuincentrum Boers B.V";

    return (
        <>
        <PageHero image={"./tuincentrum-voorkant.jpg"} imageAlt="Moestuin" />
        <Container>
        <div className="page-content">
                <Row>
                    <Col xs={12} md={6}>
                        <img src="./img/assortiment/moestuin.jpg" />
                    </Col>
                    <Col xs={12} md={6}>
                        <p>
                            Tijdens het moestuinseizoen kunt u bij ons terecht voor uw groenteplantjes, zaden, pootaardappels enz. Natuurlijk hebben wij voor u ook aardbeiplantjes en fruitbomen. Het is leuk om uw eigen groente te zaaien, maar u kunt natuurlijk ook kiezen voor groenteplantjes.
                            Wilt u zelf zaaien, ga dan in maart aan de slag. Gebruik zaai- en stekgrond voor het ontkiemen. Deze grond is fijn en gelijkmatig van structuur, goed doorlatend, maar houdt toch goed vocht vast. Laat uw plantjes vanaf half april alvast wennen aan de buitentemperatuur. Pas op voor nachtvorst en zet de plantjes 's nachts weer binnen. Bij voorkeur op een koele plek.
                        </p>
                    </Col>
                </Row>
            </div>
        </Container>
        </>
    )
}

export default Moestuin;