import React from 'react';
import { Container , Row, Col } from 'react-bootstrap';
import PageHero from '../Components/PageHero';

function Welkom() {
    document.title = "Welkom - Tuincentrum Boers B.V";

    return (
        <>
        <PageHero image={"./tuincentrum-voorkant.jpg"} imageAlt="Welkom bij Tuincentrum Boers" />
        <Container>
        <div className="page-content">
                <Row>
                    <Col xs={12} md={6}>
                        <p>
                            Leuk dat u een kijkje neemt op onze website en wij u zo een indruk kunnen geven van ons tuincentrum. Als middelgroot tuincentrum is Tuincentrum Boers B.V. al jaren een begrip in Maasdijk en omgeving. De nadruk ligt bij ons op “groen” en u treft bij ons dan ook jaarrond een ruime sortering kamer- en tuinplanten aan. Daarnaast hebben wij een gezellige sfeerafdeling waar u terecht kunt voor leuke cadeau- en sfeerartikelen voor aantrekkelijke prijzen. U vindt bij ons altijd een passend cadeau in elke gewenste prijsklasse.
                        </p>

                        <h2>Voorzieningen</h2>
                        <p>
                            Ruime parkeergelegenheid, waaronder drie parkeerplaatsen voor mindervaliden direct bij de ingang. Ons tuincentrum is rolstoelvriendelijk en beschikt over een mindervalidentoilet. U kunt bij de klantenservice een rolstoel lenen voor gebruik binnen ons tuincentrum. Wij adviseren u wel om deze van te voren telefonisch te reserveren om teleurstelling te voorkomen. Bent u moe van het shoppen, dan kunt u even uitrusten met een lekker bakje koffie of thee in onze koffiehoek.
                        </p>
                    </Col>
                    <Col xs={12} md={6}>
                        <img src="./img/welkom/tuincentrum.jpg" />
                    </Col>
                </Row>
            </div>
        </Container>
        </>
    )
}

export default Welkom;